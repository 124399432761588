@import 'styles/site.sass'

.Main
  @extend %round-section
  position: relative
  background: $black
  color: $peach-200
  &::after
    position: absolute
    content: ""
    top: 0
    left: 0
    right: 0
    border-top: 1px solid rgba(255, 255, 255, 0.2)
  
  .content
    padding: $space-m 0
    @include min($screen-l)
      padding: $space-m 0
    @include min($screen-m)
      // display: flex
      // align-items: flex-start
      // justify-content: flex-end
      display: grid
      grid-gap: size(24)
      grid-template-columns: 1fr 1fr 1fr
    
  .block
    //flex: 1
    @include min($screen-m)
      //width: 33%
    // &:first-child
    //   flex: 0.75
    @include max($screen-l)
      &:not(:last-child)
        margin-bottom: units(5)
      
    h5
      @extend %heading-m
      margin-bottom: units(1)
      
    p
      @extend %body-m
    

  li
    cursor: pointer
    position: relative
    @extend %body-s
    display: flex
    align-items: center
    height: units(5)
    @extend %links 
    
    &.notSub 
    
    &.sub
      margin-left: units(3)
      
      &+.notSub
        margin-top: units(4)
        padding-top: units(1)
        
        &:before
          content: ""
          display: block
          position: absolute
          top: -units(1)
          left: 0
          width: units(20)
          height: size(1)
          background: currentColor
      
      
      &:after
        width: units(0.75)
        display: block !important
      &:hover
        opacity: 1
        &:after
          width: units(2)
          left: -units(4)
    
    &:after
      left: -units(3)
