@import 'styles/site.sass'

.Main
  cursor: pointer
  color: $black
  max-width: $text-max-width
  position: relative
  margin-bottom: $space-l
  
  p
    @extend %body-m
    
  h5
    @extend %body-m
    @extend %medium
    
  h4
    @extend %body-m
    @extend %bold
    
  .backer
    @include background-block($white, -units(6))
    
  header
    margin-bottom: units(3)
    @include animate(0.3s)
    background: url(../../images/icons/icon-navigation-arrow.png) no-repeat center right
    background-size: auto units(3)
    
  &:hover
    .backer
      opacity: 1
    header
      background-position: right units(3) center
      text-decoration: underline
      
    .block
      //display: block
    
  .block
    height: units(1)
    background: $black
    width: $block-width
    display: none
