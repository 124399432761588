@import 'styles/site.sass'

.Main
  @extend %typography-hierarchy
  max-width: $text-max-width

  h3
    margin-bottom: units(4)

h3
  margin-bottom: units(8)
h4
