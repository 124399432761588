@import 'styles/site.sass'

.Main
  color: currentColor
  max-width: $text-max-width
  
  //background: white
  //border-radius: 40px
  //padding: 40px
  margin-bottom: size(56)
  break-inside: avoid

  @include min($screen-m)
    //border-left: 1px solid currentColor
    padding-left: size(20)
    
  
  p
    @extend %body-s
    margin-bottom: units(2)
    
  h5
    @extend %heading-s
    
  &:not(:last-child)
    h5
      // margin-bottom: $space-m
      // @include min($screen-l)
      //   margin-bottom: $space-l
      
    .block
      //display: block
    
  .block
    height: units(1)
    background: $peach-200
    width: $block-width
    display: none
