@import 'styles/site.sass'

.Main
  //@extend %round-section
  background: $white//$grey-100
  color: $black
  padding: units(8) 0
  border-top: 1px solid rgba(black, 0.2)

  @include min($screen-m)
    padding: units(15) 0

  h2
    @extend %heading-l
    margin-bottom: size(44)

    @include min($screen-m)
      margin-bottom: -size(32)
  
  .content
    // display: grid
    @include min($screen-m)
      padding-left: 25%
      column-count: 2
      column-gap: size(64)
      //align-items: flex-end
